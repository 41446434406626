<template>
  <div v-if="!isEmpty(sliderTeaserResults)">
    <span class="global-placeholder-el-large"></span>

    <Overview :slider-teasers="sliderTeaserResults" />
  </div>
</template>

<script lang="ts" setup>
import type { Overview } from '@components/Overview/model';
import type { SliderTeaser } from '@components/Slider/Teaser/models';
import type { Box as TeaserBox } from '@components/Teaser/Box/models';
import type { RawEventListItemFragment } from '@gql/fragments/__generated/RawEventListItem';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import { WhlModuleType } from '@models/WhlModuleType';
import { WhlPageType } from '@models/WhlPageType';
import isEmpty from '@utils/isEmpty';

definePageMeta({
  whlModuleType: WhlModuleType.EVENT,
  whlPageType: WhlPageType.OVERVIEW,
  //middleware: ['whl-module-type-tracker', 'whl-page-type-tracker'],
});

const { t, d } = useI18n();

const widgetConfig = await useWidgetConfig();
const instanceConfig = useWhlInstanceConfig();
ensureWhlModuleIsEnabled(widgetConfig, WhlModuleType.EVENT);
const widgetTypeConfig = useWidgetTypeConfig(
  widgetConfig
) as ComputedRef<RawWidgetConfigEventDefFragment>;

const searchStore = useSearchStore();
searchStore.readStateFromRoute();

useWhlSeoMeta({
  title: t('pages.events.overview.title'),
});

const baseFilter = buildEventBaseFilter(widgetConfig);

const sliderTeaserResults: Overview['sliderTeasers'] = await Promise.all(
  (widgetTypeConfig.value.entryPageCategories ?? [])
    .filter(
      (category) =>
        !isEmpty(category.category?.id) &&
        !isEmpty([category.label, category.category?.i18nName])
    )
    .map(async (category) => {
      const userFilter = { categories: { oneOf: [category.category!.id!] } };
      const { data: relatedRecords } = await useAsyncData(
        `eventHighlighted_${category.category?.id}`,
        () =>
          fetchEvents(widgetConfig, userFilter, baseFilter).then(
            (data) => data.events.value
          )
      );

      return {
        content: {},
        config: {
          type: 'TeaserBox',
        },
        title: {
          content: {
            main: category.label || category.category!.i18nName!,
          },
        },
        items: {
          config: {
            type: 'TeaserBox',
          },
          content: {
            items:
              relatedRecords.value?.map(
                (event: RawEventListItemFragment): TeaserBox => {
                  const {
                    nextEventDateAndTimeConsideringDate,
                    allEventDatesLinkList,
                  } = transformEventDateData(event, { t, d }, 'medium');

                  const { toBaseImage: image } = mapImxPlatformMedia(
                    event.image
                  );

                  const infoTagItems = [];
                  if (
                    !isEmpty(nextEventDateAndTimeConsideringDate.value?.date)
                  ) {
                    infoTagItems.push(
                      nextEventDateAndTimeConsideringDate.value!.date!
                    );
                  }
                  if (
                    !isEmpty(nextEventDateAndTimeConsideringDate.value?.time)
                  ) {
                    infoTagItems.push(
                      nextEventDateAndTimeConsideringDate.value!.time!
                    );
                  }

                  const categories =
                    event.categories?.filter(
                      (category) => !isEmpty(category) && isDefined(category.id)
                    ) ?? [];

                  const customFallbackImagePath = toValue(
                    prefixUrlPathWithBaseURL(
                      getCustomFallbackImagePath(
                        categories,
                        instanceConfig.value.event
                          ?.customCategoryFallbackIcons ?? {}
                      ) || instanceConfig.value.event?.customFallbackIcon
                    )
                  );

                  const category = categories
                    ?.filter(
                      (category) =>
                        isDefined(category) &&
                        isDefined(category.parent) &&
                        !isEmpty(category.i18nName)
                    )
                    ?.map((category) => {
                      if (
                        instanceConfig.value.event?.detail?.hero?.categoryBlacklistRegex?.test(
                          category.i18nName!
                        )
                      ) {
                        return category.parent?.i18nName;
                      }
                      return category.i18nName;
                    })
                    .filter((categoryName) => !isEmpty(categoryName))
                    .at(0);

                  return {
                    banner: null,
                    content: null,
                    config: {
                      id: event.id,
                      moduleType: event.__typename as WhlModuleType,
                    },
                    layout: null,
                    like: null,
                    text: {
                      content: null,
                      config: {
                        linkTo: toValue(
                          buildLinkToDetailsPage(event.permaLink)
                        ),
                      },
                      datelist: !isEmpty(allEventDatesLinkList.value)
                        ? allEventDatesLinkList.value
                        : null,
                      infoTags: {
                        content: {
                          items: infoTagItems,
                        },
                      },
                      postAddress: !isEmpty(event.contact?.title)
                        ? {
                            content: {
                              items: [
                                {
                                  icon: 'ion:location-sharp',
                                  text: instanceConfig.value.event?.overview
                                    ?.slider?.teaser?.showContactAddress
                                    ? joinNonEmptyStrings(
                                        [
                                          event.contact?.title,
                                          event.contact?.contact1?.address
                                            ?.city,
                                        ],
                                        ', '
                                      )
                                    : event.contact?.title,
                                },
                              ],
                            },
                          }
                        : null,
                      title: {
                        content: {
                          main: event.title,
                          roof: category,
                        },
                        config: {
                          showCategory:
                            instanceConfig.value.event?.overview?.slider?.teaser
                              ?.showCategory,
                        },
                      },
                    },
                    fig: {
                      content: image.value,
                      config: {
                        displayFallbackImage:
                          instanceConfig.value.event?.overview?.slider?.teaser
                            ?.displayFallbackImage,
                      },
                      figFallback: {
                        config: {
                          customFallbackImagePath,
                        },
                      },
                    },
                    /* cancelled: event.cancelled,
                    fig: convertImxPlatformImage(image), */
                  } satisfies TeaserBox;
                }
              ) ?? [],
          },
        },
      } satisfies SliderTeaser;
    })
);
</script>
